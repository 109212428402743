.slide-from-bottom-enter {
  opacity: 0;
  transform: translateY(200px);
}
.slide-from-bottom-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 200ms ease-out;
}
.slide-from-bottom-exit {
  opacity: 1;
  transform: translateY(0px);
}
.slide-from-bottom-exit-active {
  opacity: 0;
  transform: translateY(200px);
  transition: all 200ms ease-out;
}

.scale-enter {
  scale: 0;
}
.scale-enter-active {
  scale: 1;
  transition: all 150ms ease-out;
}
.scale-exit {
  scale: 1;
}
.scale-exit-active {
  scale: 0;
  transition: all 150ms ease-out;
}

.collapsed-drawer-enter {
  opacity: 0;
  max-height: 0; 
  transition: opacity 300ms, max-height 2000ms;
}
.collapsed-drawer-enter-active {
  opacity: 1;
  max-height: 2000px;
}
.collapsed-drawer-exit {
  opacity: 1;
  max-height: 2000px;
  transition: opacity 500ms, max-height 1000ms;
}
.collapsed-drawer-exit-active {
  /* opacity: 0; */
  max-height: 0;
  transition: all 500ms ease-out;
}

/* return button slide in and out animation */

.slide-transition-enter {
  opacity: 0;
  transform: translateX(0%);
  transition: opacity 500ms, transform 250ms;
}

.slide-transition-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.slide-transition-enter-done {
  animation: enterAnimation 500ms;
}

.slide-transition-exit {
  opacity: 1;
  transform: translateX(50%);
  transition: opacity 500ms, transform 500ms;
}

.slide-transition-exit-active {
  opacity: 0;
  transform: translateX(35%);
}

@keyframes enterAnimation {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

/* Frontpage map with load animation */

.fade-map-enter {
  opacity: 0;
}

.fade-map-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-map-exit {
  opacity: 1;
}

.fade-map-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

/* Tab switch */

.button-slider-container {
  display: flex;
  align-items: center;
  position: relative;
  /* border: 1px solid #dcdcdc; */
  border-radius: 6px;
  overflow: hidden;
  background-color: #FAFAFA;
  font-weight: 700;
}

.button-slider-button-left {
  padding: 10px 20px;
  border: 1px solid #dcdcdc; /* rgba(220, 220, 220, 0.25); */
  border-right: none;
  border-radius: 6px 0 0 6px;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  color: #000;
  z-index: 1;
  float: left;
}

.button-slider-button-right {
  padding: 10px 20px;
  border: 1px solid #dcdcdc; /* rgba(220, 220, 220, 0.25); */
  border-left: none;
  border-radius: 0 6px 6px 0;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  color: #000;
  z-index: 1;
  float: right;
}

.button-slider-button-middle {
  padding: 10px 20px;
  border: 1px solid #dcdcdc; /* rgba(220, 220, 220, 0.25); */
  border-left: none;
  border-right: none;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  color: #000;
  z-index: 1;
  float: middle;
}

.button-slider-button-right.active,
.button-slider-button-left.active,
.button-slider-button-middle.active {
  /* background-color: rgba(255, 0, 0, 0.1); */
  color: #fff;
  transition: .2s ease-out;
  /* border-color: #264166; */
}

.button-slider-highlight {
  position: absolute;
  bottom: 0;
  width: 33%;
  height: 41px;
  background-color: #1C568F;
  transition: left .2s ease-out;
}

.fade-in-enter {
  opacity: 0;
  scale: 0.5;
}
.fade-in-enter-active {
  opacity: 1;
  scale: 1;
  transition: all 300ms ease-out;
}
.fade-in-exit {
  opacity: 1;
}
.fade-in-exit-active {
  opacity: 0;
  transition: all 300ms ease-out;
}

.fade-in-scale-down-enter {
  opacity: 0;
  scale: 1.1;
}
.fade-in-scale-down-enter-active {
  opacity: 1;
  scale: 1;
  transition: all 300ms ease-out;
}
.fade-in-scale-down-exit {
  opacity: 1;
}
.fade-in-scale-down-exit-active {
  opacity: 0;
  transition: all 300ms ease-out;
}

/* Fade logo to banner */

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 200ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

.loading-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar {
  height: 4px;
  width: 25px;
  background-color: #6281ab;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: slide 1.5s infinite ease-in-out;
}

.bar-reverse {
  height: 4px;
  width: 25px;
  background-color: #6281ab;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: slide 1.45s infinite ease-in-out;
}

@keyframes slide {
  0% {
    transform: translateX(-50%); /* Start from the left */
  }
  50% {
    transform: translateX(50%); /* Slide to the right */
  }
  100% {
    transform: translateX(-50%); /* Return to the left */
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
