.slide-enter {
    transform: translateX(-100%);
  }
  
  .slide-enter-active {
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out;
  }
  
  .slide-exit {
    transform: translateX(0%);
  }
  
  .slide-exit-active {
    transform: translateX(-100%);
    transition: transform 1.5s ease-in-out;
  }
  