@import url("https://fonts.googleapis.com/css?family=Sofia+Sans:300,400,500,600,700,900");

body {
  margin: 0;
  font-family: "Sofia Sans";
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

::-webkit-scrollbar {
  background: transparent;
  width: 0px;
  height: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  height: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #1c568f;
  border-radius: 6px;
}

.loader {
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-bottom-color: #1c568f;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

.page-row-navigation {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background-color: #fafafa;
}

.page-button {
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  border: 1px solid rgba(220, 220, 220, 0.25);
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  color: #000;
  z-index: 1;
  float: left;
}

.page-button:active {
  background-color: #c7c7c7;
}

.page-button-arrow {
  width: auto;
  padding: 10px 15px;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  color: #000;
  z-index: 1;
  display: flex;
  align-items: center;
}
